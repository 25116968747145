








































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_CUSTOM_CONFIGURATION, GET_BACKGROUND_COLOR, SET_BACKGROUND_COLOR } from '@/modules/employee/store';
import { Grayscale } from 'vue-color';
import { AddConfigurationParams, CurrentUserResponse } from '../types';

const Employee = namespace('employee');

@Component({
  components: {
    Grayscale
  }
})
export default class BackgroundConfiguration extends Vue {
  @Employee.Mutation(SET_BACKGROUND_COLOR) setBackgroundColor!: (color?: string) => void;
  @Employee.Getter(GET_BACKGROUND_COLOR) backgroundColor!: string;
  @Employee.Action(ADD_CUSTOM_CONFIGURATION) saveConfiguration!: (data: AddConfigurationParams) => void;

  error = null;
  loading: boolean = false;
  success: boolean = false;
  changed: boolean = false;

  baseColor: string = '';

  palette: string[] = [
    '#FFFFFF',
    '#FAFAFA',
    '#F2F2F2',
    '#E6E6E6',
    '#D9D9D9',
    '#CCCCCC',
    '#BFBFBF',
    '#B3B3B3'
  ];

  get color(): string {
    return this.backgroundColor;
  }

  set color(color: string) {
    this.changed = true;

    this.setBackgroundColor(color);
  }

  revert() {
    this.changed = false;
    this.setBackgroundColor(this.baseColor);
  }

  saveBackgroundColor() {
    return this.saveConfiguration({
      employee_id: (this.$auth.user().id as string),
      configuration: 'background_color',
      value: this.color
    });
  }

  saveSuccess() {
    this.loading = true;

    const callback = ({ body }: CurrentUserResponse) => {
      this.changed = false;
      this.success = true;
      this.setBackgroundColor(body.configurations.background_color);

      this.baseColor = body.configurations.background_color;
      this.loading = false;
    };

    setTimeout(
      () =>
        this.$auth.fetch({
          success: callback
        }),
      2000
    );

    setTimeout(() => {
      this.success = false;
    }, 3000);
  }

  created() {
    window.onbeforeunload = () => {
      this.setBackgroundColor(
        this.$auth.user().configurations.background_color || this.baseColor
      );
    };

    this.baseColor = this.backgroundColor;
  }

  destroyed() {
    window.onbeforeunload = () => {
      console.info('unload');
    };

    this.setBackgroundColor(
      this.$auth.user().configurations.background_color || this.baseColor
    );
  }
}
