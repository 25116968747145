













































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_CUSTOM_CONFIGURATION } from '@/modules/employee/store';
import { Grayscale } from 'vue-color';
import { AddConfigurationParams, TermOrdering } from '../types';

const Employee = namespace('employee');

@Component({
  components: {
    Grayscale
  }
})
export default class TermOrderingConfiguration extends Vue {
  @Employee.Action(ADD_CUSTOM_CONFIGURATION) saveConfiguration!: (data: AddConfigurationParams) => void;

  error = null;
  loading: boolean = false;
  success: boolean = false;
  changed: boolean = false;

  value: TermOrdering = this.userOrdering;

  get userOrdering(): TermOrdering {
    return this.$auth.user().configurations.term_ordering;
  }

  revert() {
    this.changed = false;
    this.value = this.userOrdering;
  }

  save() {
    return this.saveConfiguration({
      employee_id: (this.$auth.user().id as string),
      configuration: 'term_ordering',
      value: this.value
    });
  }

  saveSuccess() {
    this.loading = true;

    const callback = () => {
      this.changed = false;
      this.success = true;
      this.loading = false;
    };

    setTimeout(
      () =>
        this.$auth.fetch({
          success: callback
        }),
      2000
    );

    setTimeout(() => {
      this.success = false;
    }, 3000);
  }
}
