











import { Component, Vue } from 'vue-property-decorator';
import BackgroundConfiguration from '../components/BackgroundConfiguration.vue';
import TermOrderingConfiguration from '../components/TermOrderingConfiguration.vue';

@Component({
  components: { TermOrderingConfiguration, BackgroundConfiguration }
})
export default class Configuration extends Vue {
}
